import {Subject} from "rxjs";


export class NotificationService {

  public $notificationSubject = new Subject<any>();

  public showNotification(title: string, message: string) {
    this.$notificationSubject.next({title: title, message: message});
  }

}
